(function () {
  'use strict';

  angular
    .module('events.people.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.people.list', {
        url: '?q&p',
        params: {
          q: {
            value: '',
            squash: true
          },
          p: {
            value: '1',
            squash: true
          }
        },
        reloadOnSearch: false,
        templateUrl: 'people/list/list.tpl.html',
        controller: 'PeopleListCtrl',
        controllerAs: 'vm'
      });
  }
}());
